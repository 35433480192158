<!-- 软件升级 -->
<template>
  <div id="edgeUpgradeDialog" ref="edgeUpgradeDialog">
      <a-modal  
        v-model="visible"
        :title="title" 
        :width="lineType==2?900:800"
        @ok="edgeModalHandleOk"
        :ok-button-props="okButtonProps"
        @cancel="edgeModalHandleCancel"
        :getContainer="() => this.$refs.edgeUpgradeDialog"
        centered
        :destroyOnClose="true"
        v-drag
      >
        <div v-loading="loading" :element-loading-text="$t('alarm.a0')">
            <a-row v-if="lineType==2">
                <a-col :span="24" class="step-box">
                  <a-steps type="navigation" size="small">
                    <a-step status="process">
                      <template slot="title">
                        <!-- 选择网关/控制器 -->
                          {{$t('edge.edge080')}}
                      </template>
                    </a-step>
                      <a-step  status="process ">
                      <template slot="title">
                        <!-- 选择动作命令 -->
                          {{$t('edge.edge081')}}
                      </template>
                    </a-step>
                      <a-step  status="process ">
                      <template slot="title">
                        <!-- 选择文件 -->
                          {{$t('edge.edge082')}}
                      </template>
                    </a-step>
                      <a-step  status="process ">
                      <template slot="title">
                        <!-- 确认 -->
                          {{$t('edge.edge083')}}
                      </template>
                    </a-step>
                  </a-steps>
                </a-col>
            </a-row>
            <a-row v-else>
                <a-col :span="24" class="step-box">
                  <a-steps type="navigation" size="small">
                    <a-step status="process">
                      <template slot="title">
                          {{$t('edge.edge080')}}
                      </template>
                    </a-step>
                      <a-step  status="process ">
                      <template slot="title">
                        {{$t('edge.edge082')}}
                      </template>
                    </a-step>
                      <a-step  status="process ">
                      <template slot="title">
                        {{$t('edge.edge083')}}
                      </template>
                    </a-step>
                  </a-steps>
                </a-col>
            </a-row>
            <!-- <a-row>
              <a-col :span="24" style="border-bottom:1px solid #cccccc;margin-bottom:5px;padding-bottom:5px;display:none;">
                  <span>{{$t('edge.edge055')}}：</span>
                  <a-select v-model="fileType" style="width:150px;" @change="onFileTypeChange">
                        <a-select-option
                            v-for="(itme, index) in types"
                            :key="index"
                            :value="itme.value"
                            >
                            {{ itme.label }}
                        </a-select-option>
                    </a-select>
              </a-col>
            </a-row> -->
            <a-row>
              <a-col :span="lineType==2?8:12">
                  <h3 :style="{ marginBottom: '16px' }">
                    <!-- 待升级软件网关/控制器列表 -->
                    {{$t('edge.edge061')}}
                  </h3>
                  <div style="border:1px solid #cccccc;height:420px;margin-left:5px;">
                    <a-list bordered :data-source="edges">
                      <a-list-item slot="renderItem" slot-scope="item, index" style="display:flex;justify-content: space-between;">
                        <span>{{ (index+1) + '、'+ (item.type=='GWC-CN'?( item.name ):(item.buildName + '-'+ item.buildCode + '-'+ item.name )) }}</span>
                        <a-tooltip placement="right" :title="item.msg">
                            <a-icon v-if="item.status==1" type="check-circle" style="color:green;fontSize:20px;"/>
                            <a-icon v-else-if="item.status==0" type="close-circle" style="color:red;fontSize:20px;" />
                            <a-icon v-else type="clock-circle" style="color:orange;fontSize:20px;"/>
                        </a-tooltip>
                      </a-list-item>
                    </a-list>
                  </div>
              </a-col>
              <a-col v-if="lineType==2" :span="8">
                  <h3 :style="{ marginBottom: '16px' }">
                      <!-- 动作命令 -->
                      {{$t('edge.edge078')}}： 
                  </h3>
                    <div class="command-box">
                      <div class="command-box-item">
                            <div class="ant-form-item-label">
                            {{$t('edge.edge079')}}： 
                            <!-- コマンドの種類： -->
                            </div>
                            <a-select v-model="action" style="width:150px;" @change="onCommandChange">
                                <a-select-option
                                    v-for="(itme, index) in commands"
                                    :key="index"
                                    :value="itme.value"
                                    >
                                    {{ itme.label }}
                                </a-select-option>
                            </a-select>
                      </div>
                      <div class="command-box-item">
                          <div class="ant-form-item-label">{{$t('edge.edge055')}}：</div>
                          <a-select v-model="fileType" :disabled="!(action==2 || action==99)" style="width:150px;" @change="onFileTypeChange">
                                <a-select-option
                                    v-for="(itme, index) in types"
                                    :key="index"
                                    :value="itme.value"
                                    >
                                    {{ itme.label }}
                                </a-select-option>
                          </a-select>
                      </div> 
                    </div>
              </a-col>
              <a-col :span="lineType==2?8:12" >
                  <h3 :style="{ marginBottom: '16px' }">
                    <!-- 文件列表 -->
                      {{$t('edge.edge062')}}
                  </h3>
                    <div id="style-10" class="tree-box" style="" v-loading="floading" :element-loading-text="$t('alarm.a0')">
                      <a-directory-tree :treeData="treeData"  :expandedKeys="expandedKeys" :selectedKeys="keys"  @select="onSelect" @expand="onExpand">
                        <a-tree-node key="0-0" title="GWC">
                          <a-tree-node key="0-0-0" title="GWC-2021010101" is-leaf />
                          <a-tree-node key="0-0-1" title="GWC-2021010102" is-leaf />
                        </a-tree-node>
                        <a-tree-node key="0-1" title="FMC">
                          <a-tree-node key="0-1-0" title="FMC-2021010101" is-leaf />
                          <a-tree-node key="0-1-1" title="FMC-2021010102" is-leaf />
                        </a-tree-node>
                        <a-tree-node key="0-2" title="FLC">
                          <a-tree-node key="0-2-0" title="FLC-2021010101" is-leaf />
                          <a-tree-node key="0-2-1" title="FLC-2021010102" is-leaf />
                        </a-tree-node>
                        <a-tree-node key="0-3" title="GWC-CN">
                          <a-tree-node key="0-3-0" title="GWC-CN-2021010101" is-leaf />
                          <a-tree-node key="0-3-1" title="GWC-CN-2021010102" is-leaf />
                        </a-tree-node>
                      </a-directory-tree>
                  </div>
              </a-col>
              
            </a-row>
        </div>
      </a-modal>
  </div>
</template>

<script>
import {getFileList,upgrade} from '../../../api/edge';
const data = [
  'F000-0110-FLC-1',
  'F000-0110-FLC-2',
];
const types = [
  {value:1,label:'GWC'},
  {value:2,label:'FMC'},
  {value:3,label:'FLC'},
  {value:4,label:'GWC-CN'},
  {value:5,label:'ALL'},
];

export default {
  name: "edgeUpgradeDialog",  
  props: {
    title: {
      type: String,
      default: '软件升级',
    },
    edges:{
      type:Array,
      default:undefined,
    },
    edgeType:{
      type:Number,
      default:undefined,
    },
    edgeId:{
      type:Number,
      default:undefined,
    }
    ,eqmntId:{
      type:Number,
      default:undefined,
    }
    ,lineType:{
      type:Number,
      default:undefined,
    }
  },
  data() {
    return {
      data,
      types,
      commands:undefined,
      keys:[],
      loading : true,
      visible : false,
      fileType:undefined,
      treeData:[],
      expandedKeys:[],
      selectedRows:this.edges,
      okButtonProps:{ props: { disabled: true }, },
      fileName:undefined,
      sfileType:undefined,
      kind:undefined,
      action:undefined,
      flocale:undefined,
      floading:false,
      commandMap:{
        "ALL":[
            {value:1,label:this.$t('edge.edge084')},
            {value:2,label:this.$t('edge.edge085')},
            {value:3,label:this.$t('edge.edge086')},
            {value:99,label:this.$t('edge.edge087')},
        ],
        "Spider-GWC-CN":[
            {value:2,label:this.$t('edge.edge085')},
            // {value:3,label:'文件发送并升级'},
        ],
        "EDGE-GWC-CN":[
            {value:1,label:this.$t('edge.edge084')},
            {value:2,label:this.$t('edge.edge085')},
            {value:99,label:this.$t('edge.edge087')},
        ],
        "EDGE-FLC":[ {value:1,label:this.$t('edge.edge084')},],
        "EDGE-FMC":[ {value:1,label:this.$t('edge.edge084')},],
        "EDGE-GWC":[ {value:1,label:this.$t('edge.edge084')},],
        "Spider-FLC":[ {value:1,label:this.$t('edge.edge084')},],
        "Spider-FMC":[ {value:1,label:this.$t('edge.edge084')},],
        "Spider-GWC":[ {value:1,label:this.$t('edge.edge084')},],
      }
    };
  }, 
  mounted(){
      
  },
  methods: {
    resetForm(){
          this.keys = [];
          this.action = undefined;
          this.okButtonProps.props.disabled = true;
          let key = "ALL";
          this.flocale = "ALL";

          if(this.lineType==2){
              this.flocale = "EDGE";
              if(this.fileType==4){
                key = "EDGE-GWC-CN";
                if(this.edges.length>1){
                    key = "Spider-GWC-CN";
                }
                this.flocale = "ALL";
              }else if(this.fileType==3){
                key = "EDGE-FLC";
              }else if(this.fileType==2){
                key = "EDGE-FMC";
              }else if(this.fileType==1){
                key = "EDGE-GWC";
              }else{
                key = "EDGE";
              }
              
          }else{
              if(this.fileType==4){
                key = "Spider-GWC-CN";
              }else if(this.fileType==3){
                key = "Spider-FLC";
              }else if(this.fileType==2){
                key = "Spider-FMC";
              }else if(this.fileType==1){
                key = "Spider-GWC";
              }else{
                key = "Spider";
              }
          }
          this.commands = this.commandMap[key];
          if(this.lineType ==2){
              this.setEdgeStatus(2,this.fileType?this.$t('edge.edge088'):this.$t('edge.edge090'));
          }else{
              this.setEdgeStatus(2,this.fileType?this.$t('edge.edge089'):this.$t('edge.edge091'));
          }
     },
    edgeModalHandleOk(){

       let upgradeList = [];
       this.edges.forEach(elm => {
          upgradeList.push({
              id:elm.id,
              type:elm.type,
              buildCode:elm.buildCode,
              edgeId:elm.edgeId,
              smartGatewayId:elm.smartGatewayId
          });
       });
       let param = {
         fileType:this.fileType,
         fileName:this.fileName,
         sfileType:this.sfileType,
         upgradeList:upgradeList,
         action:(this.lineType == 2?this.action:1),
         locale:this.kind,
       }
       console.log('param',param);
       this.loading = true;
       upgrade(param)
       .then((res) => {
            console.log("Upgrade", res);      
            if(res.errorCode==='00'){
                if(res.data.upgradeList){
                    for (let i = 0; i < res.data.upgradeList.length; i++) {
                        const ele = res.data.upgradeList[i];
                        for (let j = 0; j <  this.edges.length; j++) {
                            const elm =  this.edges[j];
                            if(elm.id==ele.id && elm.type==ele.type){
                                elm.status = ele.status;
                                elm.msg = ele.msg;
                                break;
                            }
                        }
                    }
                }
                this.$message.success(res.msg);
                if( this.action==2|| this.action==99){
                    this.getUpgradeFileList( this.fileType);
                }
            } else {
                this.$message.error(res.msg);
            }
          
            this.loading = false;   
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        }); 
       
     },
    edgeModalHandleCancel(){},
    onSelect(keys, event) {
      // console.log('Trigger Select', keys, event);
      this.keys = keys;
      if(event.selectedNodes[0].$options.propsData.isLeaf){
         
          this.fileName = event.selectedNodes[0].$options.propsData.title;
          this.sfileType = event.selectedNodes[0].$parent.$options.propsData.title;
          let value = keys[0];
          let values = value.split('-');
          this.kind      = values[0];
          if(this.action==2 && this.kind == 'EDGE'){
              this.okButtonProps.props.disabled =  true;
          }else{
              this.okButtonProps.props.disabled =  false;
          }
          
      }else{
          this.fileName = undefined;
          this.sfileType = undefined;
          this.kind      = undefined;
          this.okButtonProps.props.disabled =  true;
      }
    },
    setEdgeStatus(status,msg){
       for (let j = 0; j <  this.edges.length; j++) {
            this.edges[j].status = status;
            this.edges[j].msg = msg;
        }
        console.log('edges',this.edges);
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      console.log('Trigger Expand');
    },
    onFileTypeChange(e){
      console.log("onFileTypeChange",e);
      this.getUpgradeFileList(this.fileType);
    },
    getUpgradeFileList(fileType){
      
      let param = {
          fileType:fileType,
          edgeId:this.edgeId,
          kind:this.flocale,
      };
      this.treeData = [];
      this.expandedKeys = [];
      this.keys=[];
      this.floading = true;
      console.log('param',param);
      getFileList(param)
      .then((res) => {
          console.log("GetFileList", res);      
          if(res.errorCode==='00'){ 
              res.data.forEach(ele => {
                  this.expandedKeys.push(ele.key);
                  ele.children.forEach(cele => {
                      this.expandedKeys.push(cele.key);
                  });
              });
              this.treeData = res.data;
              console.log("this.treeData", this.treeData); 
          } else {
              this.$message.error(res.msg);
          } 
          this.floading = false;   
        }).catch((err) => {
          this.floading = false;
          console.log(err);
        }); 
    },
    onCommandChange(e){
      console.log('onCommandChange>>',e);
      if(this.lineType==2){
        if(e==1){
          this.flocale = 'EDGE';
          this.fileType = this.edgeType;
        }else if(e==2){
          this.flocale = 'ALL';
        }else if(e==99){
          this.flocale = 'EDGE';
        }      
        this.fileName = undefined;
        this.sfileType = undefined;
        this.kind      = undefined;
        this.okButtonProps.props.disabled =  true; 
      } 
      this.getUpgradeFileList(this.fileType)
    },
  },
  
  watch:{
    "$parent.edgeUpgradeDialogVisible":{
      handler(val){
          if(val){              
              this.fileType = this.edgeType;
              this.resetForm();
              this.visible = val;
              this.getUpgradeFileList(this.edgeType==undefined?5:this.edgeType);
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          if(!val){
              this.$parent.edgeUpgradeDialogVisible =  this.visible;
              this.fileType = undefined;
              this.keys = [];
              this.selectedRows=[];
              this.$parent.eqmntId = undefined;
              this.$parent.edgeId = undefined;
          }
      },
      deep:true,
      immediate:true,
    },
  },

  components: {
  },
};
</script>
<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-steps-navigation {padding-top: 6px;}
div /deep/.ant-steps-navigation .ant-steps-item-container {padding-bottom: 6px;}
div /deep/ .ant-steps-navigation .ant-steps-item::before{background-color: #ffffff;}
div /deep/ .ant-steps-navigation .ant-steps-item::after {top: 64%;}

div /deep/.ant-modal-body{padding: 10px;}
.ant-form-item-label {
    /* display: inline-block; */
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
}
.step-box{border: 1px solid #7682CE;margin-bottom: 10px;}
.tree-box{  border:1px solid #cccccc;height:420px;margin-left:5px;padding:2px;overflow: auto;}
/* div /deep/.ant-modal-body .tree-box .el-loading-mask {
    position: absolute;
    z-index: 2000;
    background-color: rgba(255,255,255,.9);
    margin: 0;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    transition: opacity .3s;
} */
.command-box{border:1px solid #cccccc;height:420px;margin-left:5px;padding:2px;}
.command-box-item{
  display: flex;
  justify-content: space-between;
}
#style-10::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}
#style-10::-webkit-scrollbar-thumb {
    background-color: #AAA;
    border-radius: 5px;
    /* background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, .2) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%, rgba(0, 0, 0, .2) 75%, transparent 75%, transparent); */
}
#style-10::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%); */
    background-color: #F5F5F5;
    border-radius: 5px;
}
</style>