<!-- 文件管理 -->
<template>
  <div id="edgeFileMangementDialog" ref="edgeFileMangementDialog">
      <a-modal
        v-model="visible"
        :title="title" 
        :width="800"
        @ok="edgeModalHandleOk"
        :okText="$t('energy.enedata169')"
        :ok-button-props="okButtonProps"
        @cancel="edgeModalHandleCancel"
        :getContainer="() => this.$refs.edgeFileMangementDialog"
        centered
        :destroyOnClose="true"
        v-drag
      >
        <div v-loading="loading" :element-loading-text="$t('alarm.a0')">
            <a-row>
              <a-col :span="8">
                  <div class="ant-form-item-label">{{$t('edge.edge055')}}：</div>
                    <a-select v-model="fileType" style="width:150px;" @change="onFileTypeChange">
                          <a-select-option
                              v-for="(itme, index) in types"
                              :key="index"
                              :value="itme.value"
                              >
                              {{ itme.label }}
                          </a-select-option>
                    </a-select>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24" >
                  <h3 :style="{ marginBottom: '16px' }">
                    <!-- 文件列表 -->
                      {{$t('edge.edge062')}}
                  </h3>
                    <div id="style-10" class="tree-box" style="" v-loading="floading" :element-loading-text="$t('alarm.a0')">
                      <!--  -->
                      <a-directory-tree :treeData="treeData"  :expandedKeys="expandedKeys" @select="onSelect" :selectedKeys="keys" @expand="onExpand">
                        
                      </a-directory-tree>
                  </div>
              </a-col>
              
            </a-row>
        </div>
      </a-modal>
  </div>
</template>

<script>
import {getFileList,upgrade} from '../../../api/edge';
import Template from '../../doorDetails/components/template.vue';
const data = [
  'F000-0110-FLC-1',
  'F000-0110-FLC-2',
];
const types = [
  {value:1,label:'GWC'},
  {value:2,label:'FMC'},
  {value:3,label:'FLC'},
  {value:4,label:'GWC-CN'},
  {value:5,label:'ALL'},
];

export default {
  name: "edgeFileMangementDialog",  
  props: {
    title: {
      type: String,
      default: '文件管理',
    },
    edgeId:{
      type:Number,
      default:undefined,
    }
  },
  data() {
    return {
      data,
      types,
      keys:[],
      loading : false,
      visible : false,
      fileType: 5,
      treeData:[],
      expandedKeys:[],
      selectedRows:this.edges,
      okButtonProps:{ props: { disabled: true }, },
      fileName:undefined,
      sfileType:undefined,
      kind:undefined,
      action:undefined,
      flocale:undefined,
      floading:false,
      FDC: {'GWC':1,  'FMC':2,  'FLC':3,  'GWC-CN':4,    },
      }
  }, 
  mounted(){
      
  },
  methods: {
    resetForm(){
          this.keys = [];
          this.action = undefined;
          this.okButtonProps.props.disabled = true;
          
         
     },
    edgeModalHandleOk(){
      let upgradeList = [];
      
       let param = {
         fileType:this.FDC[this.sfileType],
         fileName:this.fileName,
         sfileType:this.sfileType,
         upgradeList:upgradeList,
         action:99,
         locale:this.kind,
       }
       console.log('param',param);
       this.loading = true;
       upgrade(param)
       .then((res) => {
            console.log("Upgrade", res);      
            if(res.errorCode==='00'){
                this.$message.success(res.msg);
                if( param.action==99){
                    this.getUpgradeFileList();
                }
            } else {
                this.$message.error(res.msg);
            }
          
            this.loading = false;   
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        }); 
       
     },
    edgeModalHandleCancel(){},
    onSelect(keys, event) {
      // console.log('Trigger Select', keys, event);
      this.keys = keys;
      if(event.selectedNodes[0].$options.propsData.isLeaf){
         
          this.fileName = event.selectedNodes[0].$options.propsData.title;
          this.sfileType = event.selectedNodes[0].$parent.$options.propsData.title;
          let value = keys[0];
          let values = value.split('-');
          this.kind      = values[0];
          this.okButtonProps.props.disabled =  false;
      }else{
          this.fileName = undefined;
          this.sfileType = undefined;
          this.kind      = undefined;
          this.okButtonProps.props.disabled =  true;
      }
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      console.log('Trigger Expand');
    },
    onFileTypeChange(e){
      console.log("onFileTypeChange",e);
      this.getUpgradeFileList();
    },
    getUpgradeFileList(){
      let param = {
          fileType:this.fileType?this.fileType:5,
          edgeId:this.edgeId,
          kind:'ALL',
      };
      this.treeData = [];
      this.expandedKeys = [];
      this.keys=[];
      this.floading = true;
      console.log('param',param);
      getFileList(param)
      .then((res) => {
          console.log("GetFileList", res);      
          if(res.errorCode==='00'){ 
              res.data.forEach(ele => {
                  this.expandedKeys.push(ele.key);
                  ele.children.forEach(cele => {
                      this.expandedKeys.push(cele.key);
                  });
              });
              this.treeData = res.data;
              console.log("this.treeData", this.treeData); 
          } else {
              this.$message.error(res.msg);
          } 
          this.floading = false;   
        }).catch((err) => {
          this.floading = false;
          console.log(err);
        }); 
    },
    
  },
  
  watch:{
    "$parent.edgeFileDialogVisible":{
      handler(val){
          if(val){              
              this.resetForm();
              this.visible = val;
              this.getUpgradeFileList();
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          if(!val){
              this.$parent.edgeFileDialogVisible =  this.visible;
              this.fileType = undefined;
              this.keys = [];
              this.selectedRows=[];
          }
      },
      deep:true,
      immediate:true,
    },
  },

  components: {
    Template
  },
};
</script>
<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-steps-navigation {padding-top: 6px;}
div /deep/.ant-steps-navigation .ant-steps-item-container {padding-bottom: 6px;}
div /deep/ .ant-steps-navigation .ant-steps-item::before{background-color: #ffffff;}
div /deep/ .ant-steps-navigation .ant-steps-item::after {top: 64%;}

div /deep/.ant-modal-body{padding: 10px;}
.ant-form-item-label {
    /* display: inline-block; */
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
}
.step-box{border: 1px solid #7682CE;margin-bottom: 10px;}
.tree-box{  border:1px solid #cccccc;height:420px;margin-left:5px;padding:2px;overflow: auto;}

.command-box{border:1px solid #cccccc;height:420px;margin-left:5px;padding:2px;}
.command-box-item{
  display: flex;
  justify-content: space-between;
}
#style-10::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}
#style-10::-webkit-scrollbar-thumb {
    background-color: #AAA;
    border-radius: 5px;
    /* background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, .2) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%, rgba(0, 0, 0, .2) 75%, transparent 75%, transparent); */
}
#style-10::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%); */
    background-color: #F5F5F5;
    border-radius: 5px;
}
</style>