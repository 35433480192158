<!-- 上传升级文件 -->
<template>
  <div id="edgeUpgradeFileDialog" ref="edgeUpgradeFileDialog">
      <a-modal 
        v-model="visible"
        :title="title" 
        :okText="okText"
        :ok-button-props="okButtonProps"
        @ok="edgeModalHandleOk"
        @cancel="edgeModalHandleCancel"
        :getContainer="() => this.$refs.edgeUpgradeFileDialog"
        centered
        :destroyOnClose="true"
        v-drag
      >
        <div v-loading="uploading" :element-loading-text="uploadingTxt">
            <a-row>
              <a-col :span="24" style="padding:10px;">
                <span>{{$t('edge.edge055')}}：</span>
                <a-select v-model="fileType" style="width:150px;">
                      <a-select-option
                          v-for="(itme, index) in types"
                          :key="index"
                          :value="itme.value"
                          >
                          {{ itme.label }}
                      </a-select-option>
                  </a-select>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24"  style="padding:10px;">
                <div class="clearfix">
                  <a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
                    <a-button> <a-icon type="upload" /> {{$t('edge.edge056')}} </a-button>
                  </a-upload>
                  <!-- <a-button
                    type="primary"
                    :disabled="fileList.length === 0"
                    :loading="uploading"
                    style="margin-top: 16px"
                    @click="handleUpload"
                  >
                    {{ uploading ? 'Uploading' : 'Start Upload' }}
                  </a-button> -->
                </div>
              </a-col>
            </a-row>
            
        </div>
      </a-modal>
  </div>
</template>

<script>
import {uploadFile} from '../../../api/edge';

const types = [
  {value:1,label:'GWC'},
  {value:2,label:'FMC'},
  {value:3,label:'FLC'},
  {value:4,label:'GWC-CN'},
];
export default {
  name: "edgeUpgradeFileDialog",  
  props: {
    title: {
      type: String,
      default: '上传软件文件',
    },
    id: {
      type: String,
      default:0,
    }
  },
  data() {
    return {
      types,
      loading : false,
      visible : false,
      upurl:"",
      headers: {
        authorization: 'authorization-text',
      },
      fileType:1,
      fileList:[],
      uploading:false,
      okText: this.$t('edge.edge058'),
      uploadingTxt:this.$t('edge.edge057'),
      okButtonProps:{ props: { disabled: true }, },
    };
  }, 
  methods: {
     resetForm(){

     },
     edgeModalHandleOk(){
       this.handleUpload();
     },
     edgeModalHandleCancel(){},
     handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.okButtonProps.props.disabled =  (this.fileList.length ===0);
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.okButtonProps.props.disabled =  (this.fileList.length ===0);
      return false;
    },
    handleUpload() {
      const { fileList } = this;
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append('file', file);
      });
      formData.append('fileType', this.fileType);
      this.uploading = true;
      this.okText = this.uploadingTxt;
      // You can use any AJAX library you like
      uploadFile(formData)
      .then((res)=>{
          console.log('UploadFile>>',res);
          if(res.errorCode=='00' && res.data){
            this.fileList = [];
            this.uploading = false;
            this.okText = this.$t('edge.edge058');
            this.$message.success(this.$t('edge.edge059'));
          }else{
              this.uploading = false;
              this.$message.error(this.$t('edge.edge060'));
          }
      }).catch((err)=>{
          this.uploading = false;
          this.okText = this.$t('edge.edge058');
          this.$message.error(this.$t('edge.edge060'));
      });
      
    },
  },
  
  watch:{
    "$parent.edgeUpgradeFileDialogVisible":{
      handler(val){
          if(val){              
                // this.resetForm();
                this.fileList =[];
                this.uploading = false;
                this.visible = val;
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          if(!val){
              this.$parent.edgeUpgradeFileDialogVisible =  this.visible;
          }
      },
      deep:true,
      immediate:true,
    },
  },

  components: {
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
</style>