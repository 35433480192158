<!--门禁模块--当次日时间表组件-->
<template>
  <div id="template" ref="template">
    <div class="header">
      <span>{{ basics.name }}</span>
      <a-icon
        :type="show ? 'down' : 'up'"
        :style="{ fontSize: '15px', color: '#7682CE' }"
        @click="conceal"
      />
    </div>
    <div :class="selectStyle">
      <div class="main-left">
        <a-row :gutter="16">
          <a-col :span="2">{{ $t("details.a54") }}</a-col>
          <a-col :span="2" :offset="10">{{ $t("details.a55") }}</a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="3">
            <a-time-picker
              v-model="data.schedule1.begin"
              style="width: 100%"
              format="HH:mm"
            />
          </a-col>
          <a-col :span="3">
            <a-time-picker
              v-model="data.schedule1.end"
              style="width: 100%"
              format="HH:mm"
            />
          </a-col>
          <a-col :span="5">
            <a-select
              v-model="data.schedule1.model"
              :getPopupContainer="() => this.$refs.template"
              style="width: 100%"
            >
              <a-select-option v-for="item in data.patterns" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3" :offset="1">
            <a-time-picker
              v-model="data.schedule2.begin"
              style="width: 100%"
              format="HH:mm"
            />
          </a-col>
          <a-col :span="3">
            <a-time-picker
              v-model="data.schedule2.end"
              style="width: 100%"
              format="HH:mm"
            />
          </a-col>
          <a-col :span="5">
            <a-select
              v-model="data.schedule2.model"
              :getPopupContainer="() => this.$refs.template"
              style="width: 100%"
            >
              <a-select-option v-for="item in data.patterns" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="2">{{ $t("details.a56") }}</a-col>
          <a-col :span="2" :offset="10">{{ $t("details.a57") }}</a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="3">
            <a-time-picker
              v-model="data.schedule3.begin"
              style="width: 100%"
              format="HH:mm"
            />
          </a-col>
          <a-col :span="3">
            <a-time-picker
              v-model="data.schedule3.end"
              style="width: 100%"
              format="HH:mm"
            />
          </a-col>
          <a-col :span="5">
            <a-select
              v-model="data.schedule3.model"
              :getPopupContainer="() => this.$refs.template"
              style="width: 100%"
            >
              <a-select-option v-for="item in data.patterns" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3" :offset="1">
            <a-time-picker
              v-model="data.schedule4.begin"
              style="width: 100%"
              format="HH:mm"
            />
          </a-col>
          <a-col :span="3">
            <a-time-picker
              v-model="data.schedule4.end"
              style="width: 100%"
              format="HH:mm"
            />
          </a-col>
          <a-col :span="5">
            <a-select
              v-model="data.schedule4.model"
              :getPopupContainer="() => this.$refs.template"
              style="width: 100%"
            >
              <a-select-option v-for="item in data.patterns" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="2">{{ $t("details.a58") }}</a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="3">
            <a-time-picker
              v-model="data.schedule5.begin"
              style="width: 100%"
              format="HH:mm"
            />
          </a-col>
          <a-col :span="3">
            <a-time-picker
              v-model="data.schedule5.end"
              style="width: 100%"
              format="HH:mm"
            />
          </a-col>
          <a-col :span="5">
            <a-select
              v-model="data.schedule5.model"
              :getPopupContainer="() => this.$refs.template"
              style="width: 100%"
            >
              <a-select-option v-for="item in data.patterns" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </div>
      <div class="main-right">
        <my-pie
          :day="basics.day"
          :time="basics.time"
          :data="data"
          :id="id"
        ></my-pie>
      </div>
    </div>
  </div>
</template>

<script>
import pin from "./pie";
import { updateScheduleTable } from "../../../api/door";
import qs from "qs";
import moment from "moment";

export default {
  props: {
    basics: {
      type: Object,
    },
    data: {
      type: Object,
      default: () => ({
        schedule1: {},
        schedule2: {},
        schedule3: {},
        schedule4: {},
        schedule5: {},
      }),
    },
    id: {
      type: String,
    },
    apiId: {
      type: Number,
    },
    type: {
      type: Number,
    },
  },
  data() {
    return {
      show: true,
      time: null,
    };
  },
  computed: {
    selectStyle: function () {
      return this.show ? "main-show" : "main-hidden";
    },
  },
  methods: {
    moment,
    conceal() {
      this.show = !this.show;
    },
    setTimetable() {
      let today = true;
      let scheduleTable = {
        id: this.apiId,
        type: this.type,
        schedule1: this.data.schedule1,
        schedule2: this.data.schedule2,
        schedule3: this.data.schedule3,
        schedule4: this.data.schedule4,
        schedule5: this.data.schedule5,
      };
      let data = {
        today,
        scheduleTable,
      };
      updateScheduleTable(data)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    "my-pie": pin,
  },
};
</script>

<style scoped>
#template {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.header {
  width: 100%;
  height: 50px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e1e2;
  background-color: #f2f4f7;
}
.header span {
  margin-right: 10px;
}
.main-show {
  display: flex;
  height: 300px;
  border: 1px solid #e0e1e2;
  border-top: none;
  transition: 500ms;
  overflow: hidden;
}
.main-hidden {
  display: flex;
  height: 0;
  transition: 500ms;
  overflow: hidden;
}
.main-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 70%;
  padding: 10px;
  font-size: 14px;
}
.main-right {
  width: 30%;
  height: 100%;
}
.main-right-header {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
}
.main-right-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}
button {
  width: 120px;
}
</style>