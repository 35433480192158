<!-- 智能网关管理 -->
<template>
    <div id="edgeSmartGateway" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <!-- 头部 -->
        <div class="header">
            <a-select v-model="type" style="width:150px;" :placeholder="$t('edge.edge071')" :title="$t('edge.edge071')" @change="typeChange">
                <a-select-option
                    v-for="(item, index) in types"
                    :key="index"
                    :value="item.value"
                    >
                    {{ item.label }}
                </a-select-option>
            </a-select>
            <a-divider type="vertical" />
            <a-input  v-model="buildCode" :placeholder="$t('edge.edge070')" :title="$t('edge.edge070')"  style="width:150px;" @change="typeChange"/>
            <a-divider type="vertical" />
            <a-button type="primary" @click="refresh()">{{$t('energy.enedata128')}}</a-button>
            <a-divider type="vertical" />
            <a-button type="primary" @click="openAddDialog(1)">{{$t('energy.enedata191')}}</a-button>
        </div>
        <!-- 表格区域 -->
        <div class="main" ref="main" v-table-size:edgeSmartGateway="tableSize">
            <a-table
                :columns="columns"
                :data-source="data"
                :row-selection="rowSelection"
                @expandedRowsChange="expandedRowsChange"
                :expanded-row-keys.sync="expandedRowKeys" 
                :pagination="false"
                :scroll="size"
                size="small"
            >
                    <span slot="connect" slot-scope="connect">
                        <a-tag
                            :key="connect"
                            :color="connect === 'connected' ? 'green':'volcano'"
                        >
                            {{ connect.toUpperCase() }}
                        </a-tag>
                    </span>
                    <span slot="runnable" slot-scope="runnable">
                        <a-tag
                            :key="runnable"
                            :color="runnable === 'RUNNING' ? 'green':'volcano'"
                        >
                            {{ runnable.toUpperCase() }}
                        </a-tag>
                    </span>

                    <span slot="status" slot-scope="status">
                        <a-tag
                            :key="status"
                            :color="(statusColorMap ? statusColorMap.get(status):'')"
                        >
                            {{ statusMap ? statusMap.get(status) :'' }}
                        </a-tag>
                    </span>
                    <template slot="operation" slot-scope="text,record" >
                        <span v-if="record.type=='GWC-CN'">
                            <a @click="() => editEdge(record.id)">{{$t('energy.enedata189')}}</a>
                            <a-divider type="vertical" />
                            <a @click="() => deleteEdge(record.id)">{{$t('energy.enedata169')}}</a>
                            <a-divider type="vertical" />
                            <a @click="() => upgrade(record)" :disabled="!(record.connect === 'connected' && record.runnable === 'RUNNING')"> {{$t('edge.edge054')}} </a>
                        </span>
                        <span v-else-if="record.type=='MiddleWare'">
                            <a @click="() => editEdge(record.id)">{{$t('energy.enedata189')}}</a>
                            <a-divider type="vertical" />
                            <a @click="() => deleteEdge(record.id)">{{$t('energy.enedata169')}}</a>
                        </span>
                          <span  v-else-if="record.type=='GWC'">
                            <a @click="() => upgrade(record)" :disabled="!(record.connect === 'connected' && record.runnable === 'RUNNING')"> {{$t('edge.edge054')}} </a>
                        </span>
                        <span  v-else>
                            <a @click="() => upgrade(record)" :disabled="!(record.connect === 'connected' && record.runnable === 'RUNNING')"> {{$t('edge.edge054')}} </a>
                            <a-divider type="vertical" v-if="record.lineType==2" />
                            <a v-if="record.lineType==2" @click="() => configuration(record)" :disabled="!(record.connect === 'connected' && record.runnable === 'RUNNING')"> {{$t('edge.edge072')}} </a>
                        </span>
                    </template>
            </a-table>
        </div>
        <!-- 底部功能栏 -->
        <div class="floor">
            <a-button type="primary" @click="batchUpgrade" :disabled="upgradeDisable">{{$t('edge.edge027')}}</a-button>
            <a-divider type="vertical" />
            <a-button type="primary" @click="openAddDialog(4)">{{$t('edge.edge028')}}</a-button>
            <a-divider type="vertical" />
            <a-button type="primary" @click="openAddDialog(5)">{{$t('edge.edge097')}}</a-button>
        </div>
        <edgeInfoDialog :title="edgeModalTitle" :id="selEdgeId" ></edgeInfoDialog>
        <edgeUpgradeDialog :title="edgeUpgradeDialogTitle" :edges="edges" :edgeType="edgeType" :edgeId="edgeId" :eqmntId="eqmntId" :lineType="lineType"></edgeUpgradeDialog>
        <edgeUpgradeFileDialog :title="edgeUpgradeFileDialogTitle" id="0"></edgeUpgradeFileDialog>
        <edgeConfigDialog :title="cfgEdgeDialogTitle" :edge="cfgEdge"></edgeConfigDialog>
        <edgeFileMangementDialog :title="edgeFileDialogTitle" :edgeId="selEdgeId"></edgeFileMangementDialog>
    </div>
</template>

<script>
import edgeInfoDialog from './edgeInfoDialog';
import edgeUpgradeDialog from './edgeUpgradeDialog';
import edgeUpgradeFileDialog from './edgeUpgradeFileDialog';
import edgeConfigDialog from './edgeConfigDialog';
import edgeFileMangementDialog from './edgeFileMangementDialog';
import {getEdgeList,deleteEdgeInfo} from '../../../api/edge';
// import global from "@/mixins/global.js";
const data = [
  {
    key: 1,
    name: '阿里云中间件',
    type:'MiddleWare',
    buildCode:'--',
    buildName:'--',
    ip: '10.98.223.47',
    connect: 'connected',
    runnable:'RUNNING',
    version:'2020120101',
    upgradeDate:'2021/12/01 09:00:52',
    children: [
      {
        key: 11,
        name: 'GWC-1',
        type:'GWC',
        buildCode:'F000-0110',
        buildName:'科学城展厅-门禁管理',
        ip: '10.98.43.101',
        connect: 'connected',
        runnable:'RUNNING',
        version:'2020120101',
        upgradeDate:'2021/12/01 09:00:52',
        children: [
             {
                key: 111,
                name: 'FLC-1',
                type:'FLC',
                buildCode:'F000-0110',
                buildName:'科学城展厅-门禁管理',
                ip: '10.98.43.101',
                connect: 'connected',
                runnable:'RUNNING',
                version:'2020120101',
                upgradeDate:'2021/12/01 09:00:52',
            },
            {
                key: 112,
                name: 'FLC-2',
                type:'FLC',
                buildCode:'F000-0110',
                buildName:'科学城展厅-门禁管理',
                ip: '10.98.43.102',
                connect: 'connected',
                runnable:'RUNNING',
                version:'2020120101',
                upgradeDate:'2021/12/01 09:00:52',
            },
        ]
      },
      {
        key: 113,
        name: 'F0000112-能源管理',
        type:'GWC',
        buildCode:'F000-0112',
        buildName:'科学城展厅-能源管理',
        ip: '10.98.143.101',
        connect: 'connected',
        runnable:'RUNNING',
        version:'2020120101',
        upgradeDate:'2021/12/01 09:00:52',
        children: [
            {
                key: 114,
                name: 'FMC-1',
                type:'FMC-CN',
                buildCode:'F000-0112',
                buildName:'科学城展厅-能源管理',
                ip: '10.98.143.102',
                connect: 'connected',
                runnable:'RUNNING',
                version:'2020120101',
                upgradeDate:'2021/12/01 09:00:52',
            },
            ]
        },
    ],
  },
  {
    key: 12,
    name: 'xx智能网关',
    type:'GWC-CN',
    buildCode:'--',
    buildName:'--',
    ip: '10.98.100.1',
    connect: 'connected',
    runnable:'RUNNING',
    version:'2020120101',
    upgradeDate:'2021/12/01 09:00:52',
    children: [
        {
        key: 121,
        name: 'GWC-1',
        type:'GWC',
        buildCode:'F000-0010',
        buildName:'XXX能源管理',
        ip: '10.98.100.2',
        connect: 'connected',
        runnable:'RUNNING',
        version:'2020120101',
        upgradeDate:'2021/12/01 09:00:52',
        children: [
            {
                key: 122,
                name: 'FMC-1',
                type:'FMC-CN',
                buildCode:'F000-0010',
                buildName:'XXX能源管理',
                ip: '10.98.100.2',
                connect: 'unconnected',
                runnable:'NO RUNNING',
                version:'2020120101',
                upgradeDate:'2021/12/01 09:00:52',
            },
            ]
        },
    ],
    },
];

const colors = ['orange','purple','green','pink','pink','red','pink'];
const types = [
  {value:'',label:'全部'},
  {value:'GWC',label:'GWC'},
  {value:'FMC',label:'FMC'},
  {value:'FLC',label:'FLC'},
  {value:'GWC-CN',label:'GWC-CN'},
];
export default {
  name: "edgeSmartGateway",  
//   mixins: [global],
  data() {
    return {
      loading: false, 
      srcData:[],
      data,
      types,
      columns:[
            {
                title: this.$t('edge.edge003'),
                dataIndex: 'name',
                key: 'name',
                width:200,
                fixed:'left',
            },
            {
                title: this.$t('edge.edge016'),
                dataIndex: 'buildCode',
                key: 'buildCode',
            },
            {
                title: this.$t('edge.edge017'),
                dataIndex: 'buildName',
                key: 'buildName',
            },
            {
                title: this.$t('edge.edge002'),
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: this.$t('edge.edge005'),
                dataIndex: 'ip',
                key: 'ip',
            },
            {
                title: this.$t('edge.edge018'),
                dataIndex: 'connect',
                key: 'connect',
                scopedSlots: { customRender: 'connect' },
                width:120,
            },
            {
                title: this.$t('edge.edge019'),
                dataIndex: 'runnable',
                key: 'runnable',
                scopedSlots: { customRender: 'runnable' },
                width:120,
            },
            {
                title: this.$t('edge.edge020'),
                dataIndex: 'connectDate',
                key: 'connectDate',
            },
            {
                title: this.$t('edge.edge021'),
                dataIndex: 'status',
                key: 'status',
                scopedSlots: { customRender: 'status' },
                 width:120,
            },
            {
                title: this.$t('edge.edge022'),
                dataIndex: 'version',
                key: 'version',
            },
            {
                title: this.$t('edge.edge023'),
                dataIndex: 'upgradeDate',
                key: 'upgradeDate',
            },
            {
                title: this.$t('edge.edge024'),
                dataIndex: 'operation',
                key: 'operation',
                scopedSlots: { customRender: 'operation' },
                fixed:'right',
                width:180,
            },
      ],
      expandedRowKeys: [],
      edgeModalTitle:"",
      edgeModalVisible:false,
      selEdgeId:0,
      edgeUpgradeDialogTitle:"",
      edgeUpgradeDialogVisible:false,
      edgeUpgradeFileDialogTitle:"",
      edgeUpgradeFileDialogVisible:false,
      editFlag:1,
      statusDatas:[
        {value:'NO_OPERATION'				   ,label:this.$t('edge.edge063')},
        {value:'TEST'                          ,label:this.$t('edge.edge064')},
        {value:'OPERATION'                     ,label:this.$t('edge.edge065')},
        {value:'WORKING_NOTIFICATION'          ,label:this.$t('edge.edge066')},
        {value:'CONCENTRATED_NOTIFICATION'     ,label:this.$t('edge.edge067')},
        {value:'SUSPENSION'                    ,label:this.$t('edge.edge068')},
        {value:'DELETED_NOTIFICATION'          ,label:this.$t('edge.edge069')},
      ],
      statusMap:undefined,
      statusColorMap:undefined,
      upgradeDisable:true,
      upgradeTypes:[],
      upgradeTypeSel:'',
      issame:true,
      size: { x: 1900, y: 360 },
      selectedRowKeys:[],
      selectedRows:new Array(),
      edges:new Array(),
      edgeType:undefined,
      type:undefined,
      ip:undefined,
      buildCode:undefined,
      edgeId:undefined,
      eqmntId:undefined,
      tag:0,
      lineType:0,
      cfgEdge:undefined,
      cfgEdgeDialogTitle:"",
      cfgEdgeDialogVisible:false,
      edgeFileDialogTitle:"",
      edgeFileDialogVisible:false,
    };
  }, 
  mounted(){
    this.statusMap = new Map();
    this.statusColorMap = new Map();
    for (let i = 0; i < this.statusDatas.length; i++) {
        const element = this.statusDatas[i];
        this.statusMap.set(element.value,element.label);
        this.statusColorMap.set(element.value,colors[i]);
    }
    this.types[0].label = this.$t('information.a15');
    this.refresh();
    this.tableSize();
  },
  watch:{
    "editFlag":{
      handler(val){
          console.log("editFlag>>",val);
          if(val===1){              
            this.refresh();
          }
      },
      deep:true,
      immediate:true,
    },
  },
  computed:{
        rowSelection(){
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    // console.log(`selectedRowKeys: ${selectedRowKeys}`);
                    this.selectedRowKeys = selectedRowKeys;
                    this.selectedRows = selectedRows;
                },
                onSelect: (record, selected, selectedRows) => {
                    if( selectedRows.length==1){
                        this.lineType = selectedRows[0].lineType;
                        this.upgradeTypeSel = selectedRows[0].type;
                        this.upgradeDisable = false;
                        this.issame = true;
                    }else if(selectedRows.length==0){
                        this.lineType =undefined;
                        this.upgradeTypeSel = undefined;
                        this.upgradeDisable = true;
                        this.issame = false;
                    }else{
                        for (let index = 0; index < selectedRows.length; index++) {
                            const element = selectedRows[index];
                            if(element.type != this.upgradeTypeSel || element.lineType != this.lineType ){
                                this.upgradeDisable = true;
                                this.issame = false;
                                break;
                            }
                        }
                    }
                    // console.log(record, selected, selectedRows);
                    // if(selected){
                    //     // console.log('selectedRows len', selectedRows.length);
                    //     if(this.upgradeTypeSel==''){
                    //         if(record.type !='MiddleWare'){
                    //             this.upgradeTypeSel = record.type;
                    //             this.upgradeDisable = false;
                    //         }
                    //     }else{
                    //         if(this.upgradeTypeSel!=record.type){
                    //             this.issame = false;
                    //             this.upgradeDisable = true;
                    //         }
                    //     }
                    // }else{
                    //     // console.log('selectedRows len', selectedRows.length);
                    //     if( selectedRows.length==0){
                    //         this.issame = false;
                    //         this.upgradeDisable = true;
                    //         this.upgradeTypeSel=''
                    //     }else  if( selectedRows.length==1){
                    //         this.upgradeTypeSel = selectedRows[0].type;
                    //         this.upgradeDisable = false;
                    //         this.issame = true;
                    //     }else{
                    //         let count = 0;
                    //         if(this.upgradeTypeSel==record.type && selectedRows.findIndex(item => item.type == record.type  )==-1){
                    //             this.upgradeTypeSel = selectedRows[0].type;
                    //         }
                    //         for (let index = 0; index < selectedRows.length; index++) {
                    //             const element = selectedRows[index];
                    //             if(this.upgradeTypeSel!=element.type){
                    //                 count ++;
                    //             }
                    //         }

                    //         if(count == 0){
                    //             this.upgradeDisable = false;
                    //             this.issame = true;
                    //         }else if(count == selectedRows.length){
                    //             this.upgradeTypeSel = selectedRows[0].type;
                    //             this.upgradeDisable = false;
                    //             this.issame = true;
                    //         }else{
                    //             this.issame = false;
                    //             this.upgradeDisable = true;
                    //         }
                    //     }
                    // }
                },
                onSelectAll: (selected, selectedRows, changeRows) => {
                    // console.log(selected, selectedRows, changeRows);
                    if(selected){
                        this.upgradeTypeSel = selectedRows[0].type;
                        for (let index = 0; index < selectedRows.length; index++) {
                            const element = selectedRows[index];
                            if(this.upgradeTypeSel!=element.type){
                                count ++;
                            }
                        }

                        if(count == selectedRows.length){
                            this.upgradeTypeSel = selectedRows[0].type;
                            this.upgradeDisable = false;
                            this.issame = true;
                        }else{
                            this.issame = false;
                            this.upgradeDisable = true;
                        }

                    }else{
                        this.issame = false;
                        this.upgradeDisable = true;
                        this.upgradeTypeSel=''
                    }
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: this.getCheckboxStatus(record),// Column configuration not to be checked
                        name: record.name,
                    },
                }),
                hideDefaultSelections:true,
            }
        }
  },
  methods: {
      typeChange(e){
          this.loading = true;
          if(this.type || this.ip || this.buildCode){
              this.filterFunc();
          }else{
              this.data = [...this.srcData];
          }
        // this.setExpandedRow();
        this.loading = false;
      },
      setExpandedRow(){
        let list2 = new Array();
        let list = [...this.data];
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            list2.push(item.key);
            if(item.children){
                item.children.forEach(ele => {
                    list2.push(ele.key);
                    // if(ele.children){
                    //      ele.children.forEach(elem => {
                    //         list2.push(elem.key);
                    //     });
                    // }
                });
            }
        }

        this.expandedRowKeys = [...list2];
        // console.log('expandedRowKeys>>>', this.expandedRowKeys);
        // console.log(`2 expandedRowKeys: ${this.expandedRowKeys}`);
      },
      filterFunc(){
        let list = [...this.srcData];
        let list2 = new Array();
        let list3 = new Array();
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            list2.push(item);
            if(item.children){
                list2.push(...item.children);
                item.children.forEach(element => {
                    if(element.children)
                        list2.push(...element.children);
                });
            }
        }

        for (let i = 0; i < list2.length; i++) {
            let item = list2[i];
            let res = true;
            if(res && this.type){
                res = item.type == this.type;
            }

            if(res && this.buildCode) {
                if(this.type || (!this.type && item.type=='GWC')){
                    res = item.buildCode == this.buildCode;
                }else{
                    res = false;
                }
            }

            if(res && this.ip && item.ip){
                res = item.ip.includes(this.ip);
            }

            if(res){
                list3.push(item);
            }
        }

        this.data = [...list3];
        console.log("this.data",  this.data);  
      },
     getCheckboxStatus(record){
         if(record.type=='MiddleWare'){
              return true;
         }
         if (record.connect != 'connected' || record.runnable != 'RUNNING'){
             return true;
         }
        return false;
     },
     expandedRowsChange(expandedRowKeys){
         if(this.tag==1){
             console.log(`1 expandedRowKeys: ${expandedRowKeys}`);
             this.expandedRowKeys = expandedRowKeys;
         }else{
             this.tag=1;
         }
     },
     configuration(edge){
        this.cfgEdge = edge;
        this.cfgEdgeDialogTitle = this.$t('edge.edge073');//"网关/控制器编号配置";
        this.cfgEdgeDialogVisible = true;
     },
     upgrade(eqmnt){
        this.edgeId         = eqmnt.edgeId;
        this.eqmntId        = eqmnt.id;
        this.lineType       = eqmnt.lineType;
        this.edgeUpgradeDialogTitle = this.$t('edge.edge027');//"软件升级";
        if(eqmnt.type=='FLC'){
            this.edgeType = 3;
        }else if(eqmnt.type=='FMC'){
            this.edgeType = 2;
        }else if(eqmnt.type=='GWC'){
            this.edgeType = 1;
        }else if(eqmnt.type=='GWC-CN'){
            this.edgeType = 4;
        }
        this.edges= new Array();
        this.edges.push(eqmnt);
        this.edgeUpgradeDialogVisible = true;
        // this.upgradeTypeSel = eqmnt.type;
        // this.openAddDialog(3);
     },
     batchUpgrade(){
        this.edgeUpgradeDialogTitle = this.$t('edge.edge027');//"软件升级";
        // this.lineType       = 0;
        if(this.upgradeTypeSel=='FLC'){
            this.edgeType = 3;
        }else if(this.upgradeTypeSel=='FMC'){
            this.edgeType = 2;
        }else if(this.upgradeTypeSel=='GWC'){
            this.edgeType = 1;
        }else if(this.upgradeTypeSel=='GWC-CN'){
            this.edgeType = 4;
        }
        this.edges  = this.selectedRows;
        this.edgeId = this.selectedRows[0].edgeId;
        this.openAddDialog(3);
     },
     openAddDialog(action){
         switch(action){
            case 1: //新增智能网关
                this.edgeModalTitle = this.$t('edge.edge025');//"新增智能网关";
                this.selEdgeId = 0;
                this.edgeModalVisible = true;
                this.editFlag = 2;
                break;
            case 2://编辑智能网关
                this.edgeModalVisible = true;
                this.editFlag = 2;
                break;
            case 3://软件升级
                
                this.edgeUpgradeDialogVisible = true;
                break;
            case 4://上传升级软件文件
                this.edgeUpgradeFileDialogTitle = this.$t('edge.edge028');//"上传升级软件文件";
                this.edgeUpgradeFileDialogVisible = true;
                break;
            case 5://上传升级软件文件
                this.edgeFileDialogTitle = this.$t('edge.edge097');//"上传升级软件文件";
                this.edgeFileDialogVisible = true;
                this.selEdgeId = undefined;
                break;
         }
     },
     refresh(){
        console.log('refresh');
        let param = {

        };
        this.srcData = [] ; 
        this.loading = true;
        getEdgeList(param)
        .then((res) => {
            console.log("GetEdgeList", res);  
            // this.data = [...res.data] ;     
            this.srcData = [...res.data] ; 
            this.typeChange();    
            this.loading = false;
            this.editFlag = 1;
        }).catch((err) => {
            this.loading = false;
            console.log(err);
        });         
     },
     edgeModalHandleOk(){

     },
     edgeModalHandleCancel(){

     },
     editEdge(id){
        this.edgeModalTitle = this.$t('edge.edge026');//"编辑智能网关";
        this.selEdgeId = id;

        this.openAddDialog(2);
     },
     deleteEdge(id){
        this.$confirm({
            title: this.$t('edge.edge053'),
            centered: true,
            onOk: () => {
               this.deleteEdgeSGWInfo(id);
            }
        });
     },
     deleteEdgeSGWInfo(id){
        this.loading = true;
        deleteEdgeInfo(id)
        .then((res) => {
            console.log("DeleteEdge", res);  
            if(res.errorCode=='00'){
                this.$message.success(res.msg);
            }else{
                this.$message.error(res.msg);
            }
            this.loading = false;
        }).catch((err) => {
            this.loading = false;
            console.log(err);
             this.$message.error();
        });     
     },
     
     tableSize(){
        this.$nextTick(()=>{
            const indexDom = document.getElementById("edgeSmartGateway");
            const height = indexDom.getElementsByClassName("main")[0].clientHeight;
            const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
            const tableHeight = height - title;
            this.size.y = tableHeight;
        })
     },
  },
  
  watch: {
    
  },

  components: {
      "edgeInfoDialog":edgeInfoDialog,
      "edgeUpgradeDialog":edgeUpgradeDialog,
      "edgeUpgradeFileDialog":edgeUpgradeFileDialog,
      "edgeConfigDialog":edgeConfigDialog,
      "edgeFileMangementDialog":edgeFileMangementDialog,
  },
};
</script>

<style scoped>
#edgeSmartGateway{
  width: 100%;
  height: 100%;
  padding: 20px 20px 0px 20px;
  position: relative;
  overflow: hidden;
}
.header{
    text-align:right;
    margin-bottom:15px;
}
.main {
  height: calc(100% - 110px);
  overflow: hidden;
}
.floor{
    padding: 15px 0px;
}
</style>