<template>
  <div id="edgeConfigDialog" ref="edgeConfigDialog"  >
      <a-modal 
        v-model="visible"
        :title="title" 
        :width="720"
        @ok="edgeConfigModalHandleOk"
        @cancel="edgeModalHandleCancel"
        :getContainer="() => this.$refs.edgeConfigDialog"     
                
      >
        <div v-loading="loading" :element-loading-text="$t('alarm.a0')">
            <a-form-model ref="edgeConfigForm" 
              :model="form" 
              :rules="rules"
              >
              <div class="base-box">
                  <a-row>
                      <a-col :span="8">
                          <!-- 网关类型 ref="lineType" prop="lineType"-->
                          <a-form-model-item :label="$t('edge.edge002')"   class="form-dis-item"> 
                              <a-select v-model="form.deviceType">
                                  <a-select-option
                                      v-for="(item, index) in types"
                                      :key="index"
                                      :value="item.value"
                                      >
                                      {{ item.label }}
                                  </a-select-option>
                              </a-select>
                          </a-form-model-item>
                      </a-col>
                  </a-row>
                  
                  <a-row>
                      
                      <a-col :span="12">
                          <a-form-model-item :label="$t('edge.edge074')"  class="form-dis-item">
                              <a-input  v-model="form.oldBuildCode" :readOnly="true" />
                          </a-form-model-item>
                      </a-col>
                        <a-col :span="12">
                          <a-form-model-item :label="$t('edge.edge075')">
                              <a-input  v-model="form.oldDeviceId" :readOnly="true" />
                          </a-form-model-item>
                      </a-col>
                  </a-row>
                  <a-row>
                      <a-col :span="12">
                          <a-form-model-item :label="$t('edge.edge076')" ref="newBuildCode" prop="newBuildCode" class="form-dis-item">
                              <a-input  v-model="form.newBuildCode" :placeholder="$t('edge.edge092')" />
                          </a-form-model-item>
                      </a-col>
                        <a-col :span="12" v-if="form.deviceType=='FLC'">
                          <a-form-model-item :label="$t('edge.edge077')" ref="newFLCDeviceId" prop="newFLCDeviceId" class="form-dis-item">
                              <a-input-number  v-model="form.newFLCDeviceId" :placeholder="$t('edge.edge093')" :min="101" :max="140" style="width:100%;"/>
                          </a-form-model-item>
                      </a-col>
                        <a-col :span="12" v-if="form.deviceType=='FMC'">
                          <a-form-model-item :label="$t('edge.edge077')" ref="newFMCDeviceId" prop="newFMCDeviceId" class="form-dis-item">
                              <a-input-number  v-model="form.newFMCDeviceId" :placeholder="$t('edge.edge093')" :min="17" :max="19" style="width:100%;"/>
                          </a-form-model-item>
                      </a-col>
                  </a-row>
              </div>
            </a-form-model>
        </div>
      </a-modal>
  </div>
</template>
<script>
import {saveEdgeConfigInfo} from '../../../api/edge';
const types = [
  {value:'FLC',label:'FLC'},
  {value:'FMC',label:'FMC'},
];


export default {
  name: "edgeConfigDialog",  
  props: {
    title: {
      type: String,
      default: "",
    },
    edge: {
      type: Object,
      default:undefined,
    }
  },
  data() {
      console.log(this.edge);
    //   let min (this.form && this.form.deviceType==='FLC'?101:17)

    let checkFLC = (rule, value, callback) => {
      clearTimeout(checkFLCVail);
      if (!value) {
        return callback(new Error(this.$t('edge.edge093')));
      }
      let checkFLCVail = setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error(this.$t('energy.enedata038')));
        } else {
          if (value < 101 || value > 140) {
            callback(new Error(this.$t('edge.edge095')));
          } else {
            callback();
          }
        }
      }, 300);
    };
    let checkFMC = (rule, value, callback) => {
      clearTimeout(checkFMCVail);
      if (!value) {
        return callback(new Error(this.$t('edge.edge093')));
      }
      let checkFMCVail = setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error(this.$t('energy.enedata038')));
        } else {
          if (value < 17 || value > 19) {
            callback(new Error(this.$t('edge.edge096')));
          } else {
            callback();
          }
        }
      }, 300);
    };
    return {
      loading : false,
      visible : false,
      types,
      form:{
        deviceType:undefined,
        oldBuildCode:undefined,
        oldDeviceId:undefined,
        newBuildCode:undefined,
        newFMCDeviceId:undefined,
        newFLCDeviceId:undefined,
      },
      rules: {
        newBuildCode: [
            { required: true, message: this.$t('edge.edge092'), trigger: 'blur' },
            { min: 8, max: 8, message: this.$t('edge.edge094'), trigger: 'blur' },
        ], 
        newFLCDeviceId: [
            { required: true, message: this.$t('edge.edge093'), trigger: 'blur' },
            { validator: checkFLC, trigger: 'blur' },
        ], 
        newFMCDeviceId: [
            { required: true, message: this.$t('edge.edge093'), trigger: 'blur' },
            { validator: checkFMC, trigger: 'blur' },
        ], 
      },
      disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
    };
  }, 
  methods: {
     resetForm(){
         while(!this.edge){
            console.log(">>>>>>>>>>>>>>");
         }
         this.form.deviceType   = this.edge.type;
         this.form.oldBuildCode = this.edge.buildCode;
         this.form.oldDeviceId  = this.edge.deviceId;
        if(this.$refs.edgeConfigForm){
            this.$refs.edgeConfigForm.resetFields();
            
        }
     },
     edgeConfigModalHandleOk(){
       this.$refs.edgeConfigForm.validate(valid => {
            console.log("edgeConfigForm validate",valid);
            if (valid) {
                //  this.setEdgeConfigInfo();
                this.$confirm({
                    title:  this.$t('edge.edge052'),//'确认要保存所设置的智能网关信息吗？',//
                    centered: true,
                    onOk: () => {
                        this.setEdgeConfigInfo();
                    },
                });
            } else {
                console.log('error submit!!');
                return false;
            }
        });

       
     },
     edgeModalHandleCancel(){},
     setEdgeConfigInfo(){
         let newDeviceId = this.form.deviceType=='FLC'?this.form.newFLCDeviceId:this.form.newFMCDeviceId;
         let param = {
            edgeId:this.edge.edgeId,
            deviceType:this.form.deviceType,
            newBuildCode:this.form.newBuildCode,
            newDeviceId:newDeviceId,
            oldBuildCode:this.form.oldBuildCode,
            oldDeviceId:this.form.oldDeviceId,
         };
         console.log("setEdgeConfigInfo", param);
         this.loading = true;

        saveEdgeConfigInfo(param)
        .then((res) => {
            console.log("GetEdgeInfo", res);      
            if(res.errorCode==='00'){
                this.$message.success(res.msg);
                
                
            } else {
                this.$message.error(res.msg);
            }
            this.loading = false;
        }).catch((err) => {
            this.loading = false;
            console.log(err);
        }); 
     },
     
  },
  
  watch:{
    "$parent.cfgEdgeDialogVisible":{
      handler(val){
          console.log("cfgEdgeDialogVisible>>",val);
           console.log("cfgEdgeDialogVisible edge>>",this.edge);
          if(val){      
            this.resetForm();
            setTimeout(() => {
                this.visible = val;
            }, 1000);        
           
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
        handler(val){
            console.log("visible",val);
            if(!val){
                this.$parent.cfgEdgeDialogVisible =  val;
            //     if(this.$parent.editFlag==3){
            //         this.$parent.refresh();
            //     }
            }
        },
        deep:true,
        immediate:true,
    },
  },

  components: {
  },
};
</script>
<style scoped>
div /deep/.ant-modal-header {
  background: #7682ce;
}
#edgeConfigDialog{
    height: 100%;
    width:100%;
    position: relative;  
}
 /* div /deep/.ant-modal-body .el-loading-mask {
    position: absolute;
    z-index: 2000;
    background-color: rgba(255,255,255,.9);
    margin: 0;
    top: -60px;
    right: -10px;
    bottom: -60px;
    left: -10px;
    transition: opacity .3s;
} */
/* div /deep/.ant-modal-body{padding: 10px;} */
/* .base-box{
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    padding: 3px;
    margin-bottom: 2px;
} */
.form-dis-item{margin-right:3px ;}
</style>