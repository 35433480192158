<!-- 只能网关管理--添加 -->
<template>
  <div id="edgeInfoDialog" ref="edgeInfoDialog"  >
      <a-modal 
        v-model="visible"
        :title="title"
        :width="800"
        @ok="edgeModalHandleOk"
        @cancel="edgeModalHandleCancel"
        :getContainer="() => this.$refs.edgeInfoDialog"
        centered
        :destroyOnClose="true"
        v-drag     
      >
        <div class="container" v-loading="loading" :element-loading-text="$t('alarm.a0')">
            <div class="main">
                <a-form-model ref="edgeInfoForm" :label-col="labelCol" :wrapper-col="wrapperCol"
                :model="form" 
                :rules="rules"
                >
                <div class="base-box">
                    <a-row>
                        <a-col :span="12">
                            <!-- 网关类型 ref="lineType" prop="lineType"-->
                            <a-form-model-item :label="$t('edge.edge002')"   class="form-dis-item"> 
                                <a-select v-model="form.lineType">
                                    <a-select-option
                                        v-for="(item, index) in types"
                                        :key="index"
                                        :value="item.value"
                                        >
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        
                        <a-col :span="12">
                            <!-- 网关名称 -->
                            <a-form-model-item :label="$t('edge.edge003')" ref="name" prop="name" class="form-dis-item">
                                <a-input  v-model="form.name" :placeholder="$t('edge.edge029')" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                            <a-col :span="12">
                            <!-- 所属位置 -->
                            <a-form-model-item :label="$t('edge.edge004')" ref="connectLinePlace" prop="connectLinePlace">
                                <a-input  v-model="form.connectLinePlace" :placeholder="$t('edge.edge030')" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        
                        <a-col :span="12">
                            <!-- 网关账户 -->
                            <a-form-model-item :label="$t('edge.edge007')" ref="account" prop="account" class="form-dis-item">
                                <a-input  v-model="form.account" :placeholder="$t('edge.edge033')" />
                            </a-form-model-item>
                        </a-col>
                            <a-col :span="12">
                            <!-- 网关密码 -->
                            <a-form-model-item :label="$t('edge.edge008')" ref="password" prop="password">
                                <a-input  v-model="form.password" :placeholder="$t('edge.edge034')" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="12">
                            <!-- 网关IP -->
                            <a-form-model-item :label="$t('edge.edge005')" ref="ip" prop="ip" class="form-dis-item">
                                <a-input  v-model="form.ip" :placeholder="$t('edge.edge031')" />
                            </a-form-model-item>
                        </a-col>
                            <a-col :span="12">
                            <!-- 端口 -->
                            <a-form-model-item :label="$t('edge.edge006')" class="form-dis-item">
                                <a-input-number  v-model="form.port" :placeholder="$t('edge.edge032')" :min="0" :max="65535" style="width:100%;"/>
                            </a-form-model-item>
                        </a-col>
                            </a-row>
                    <a-row>
                        <a-col :span="12">
                            <!-- 网关访问URL -->
                            <a-form-model-item :label="$t('edge.edge009')" ref="url" prop="url" class="form-dis-item">
                                <a-input  v-model="form.url" :placeholder="$t('edge.edge035')" />
                            </a-form-model-item>
                        </a-col>
                        
                        <!-- 状态检测URL -->
                        <a-col :span="12">
                            <a-form-model-item :label="$t('edge.edge010')" ref="lineCheckUrl" prop="lineCheckUrl">
                                <a-input  v-model="form.lineCheckUrl" :placeholder="$t('edge.edge036')" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <!-- 
                        <a-row>
                        <a-col :span="8">
                            <a-form-model-item :label="$t('edge.edge011')">
                                <a-input  v-model="form.notificationTimeLimit" :placeholder="$t('edge.edge037')" />
                            </a-form-model-item>
                        </a-col>
                    
                        <a-col :span="8">
                            <a-form-model-item :label="$t('edge.edge012')" class="form-dis-item">
                                <a-input  v-model="form.lineCheckMode" :placeholder="$t('edge.edge038')" />
                            </a-form-model-item>
                        </a-col>
                            
                            <a-col :span="8">
                            <a-form-model-item :label="$t('edge.edge013')" >
                                <a-input  v-model="form.monitoringMode" :placeholder="$t('edge.edge039')" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    -->
                    <a-row>
                        <!-- 说明 -->
                        <a-col :span="24">
                            <a-form-model-item :label="$t('energy.enedata146')"  :label-col="labelCol2" :wrapper-col="wrapperCol2">
                                <a-radio-group v-model="form.status" button-style="solid">
                                    <a-radio-button v-for="(item, index) in statusData"
                                        :key="index"
                                        :value="item.value">
                                        {{item.label}}
                                    </a-radio-button>
                                </a-radio-group>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                        <a-row>
                        <!-- 说明 -->
                        <a-col :span="24">
                            <a-form-model-item :label="$t('edge.edge014')" ref="comments" prop="comments" :label-col="labelCol2" :wrapper-col="wrapperCol2">
                                <a-textarea  v-model="form.comments" :placeholder="$t('edge.edge040')" :auto-size="{ minRows: 3, maxRows: 6 }" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>

                        <a-row>
                        <!-- 备注 -->
                            <a-col :span="24">
                            <a-form-model-item :label="$t('edge.edge015')" ref="description" prop="description" :label-col="labelCol2" :wrapper-col="wrapperCol2">
                                <a-textarea  v-model="form.description" :placeholder="$t('edge.edge041')" :auto-size="{ minRows: 2, maxRows: 6 }" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </div>
                </a-form-model>
            </div>
        </div>
      </a-modal>
  </div>
</template>

<script>
import {updateEdge,addEdge,getEdgeInfo} from '../../../api/edge';
const types = [
  {value:1,label:'MiddleWare'},
  {value:2,label:'GWC-CN'},
];


export default {
  name: "edgeInfoDialog",  
  props: {
    title: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default:0,
    }
  },
  data() {
    const normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
 
        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata218')));
        } else {
        callback();
      }
    };

    const normalString = (rule, value, callback) => {
          let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;

          if(regEn.test(value)) {
              callback(new Error(this.$t('energy.enedata218')));
          } else {
              callback();
        }
      };
    return {
      loading : false,
      visible : false,
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      labelCol2: { span: 5 },
      wrapperCol2: { span: 19 },
      types,
      form:{
            id:0,
            lineType: 1,
            name: "",
            ip: "",
            port: "",
            url: "",
            lineCheckUrl: "",
            account: "",
            password: "",
            description: "",
            comments: "",
            lineCheckMode: 0,
            monitoringMode: 0,
            notificationTimeLimit: 0,
            connectLinePlace:"",
            status:0,
      },
      rules: {
        name: [
            { required: true, message: this.$t('edge.edge029'), trigger: 'blur' },
            { min: 1, max: 30, message: this.$t('edge.edge043'), trigger: 'blur' },
            { validator:normalName, trigger: 'blur' },
        ],
        connectLinePlace: [
            { min: 1, max: 30, message: this.$t('edge.edge044'), trigger: 'blur' },
            { validator:normalString, trigger: 'blur' },
        ], 
        ip: [
            { min: 1, max: 30, message: this.$t('edge.edge045'), trigger: 'blur' },
        ], 
        lineCheckUrl: [
            { min: 1, max: 200, message: this.$t('edge.edge049'), trigger: 'blur' },
        ], 
        url: [
            { min: 1, max: 200, message: this.$t('edge.edge048'), trigger: 'blur' },
        ], 
        account: [
            { min: 6, max: 30, message: this.$t('edge.edge046'), trigger: 'blur' },
        ], 
        password: [
            { min: 6, max: 20, message: this.$t('edge.edge047'), trigger: 'blur' },
        ], 
        comments: [
            { min: 0, max: 800, message: this.$t('edge.edge050'), trigger: 'blur' },
        ], 
        description: [
            { min: 0, max: 200, message: this.$t('edge.edge051'), trigger: 'blur' },
        ], 
      },
      disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
      statusData:[
        {value:0,label:this.$t('edge.edge063')},
        {value:1,label:this.$t('edge.edge064')},
        {value:2,label:this.$t('edge.edge065')},
        // {value:3,label:this.$t('edge.edge066')},
        // {value:4,label:this.$t('edge.edge067')},
        {value:5,label:this.$t('edge.edge068')},
        // {value:6,label:this.$t('edge.edge069')},
      ],
    };
  }, 
  methods: {
     resetForm(){
        if(this.$refs.edgeInfoForm){
            this.$refs.edgeInfoForm.resetFields();
        }
     },
     edgeModalHandleOk(){
       this.$refs.edgeInfoForm.validate(valid => {
                if (valid) {
                     this.$confirm({
                        title:  this.$t('edge.edge052'),//'确认要保存所设置的智能网关信息吗？',//
                        centered: true,
                        onOk: () => {
                            this.saveEdgeSmartGateway();
                        },
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
     },
     edgeModalHandleCancel(){},
     saveEdgeSmartGateway(){
         let param = {
            // id:this.id,
            id: 						this.form.id                        ,
			name: 						this.form.name                      ,
			lineType: 					this.form.lineType                  ,
            connectLinePlace: 			this.form.connectLinePlace          ,
			ip: 						this.form.ip                        ,
            port: 						this.form.port                      ,
            url: 						this.form.url                       ,
            lineCheckUrl: 				this.form.lineCheckUrl              ,
            account: 					this.form.account					,
			password: 					this.form.password                  ,
            comments: 					this.form.comments                  ,
            description: 				this.form.description               ,
            status:                     this.form.status                    ,
            // notificationTimeLimit: 		this.form.notificationTimeLimit     ,
			// monitoringMode: 			this.form.monitoringMode            ,
			// lineCheckMode: 				this.form.lineCheckMode             ,
         };
         console.log("saveEdgeSmartGateway", param);
         this.loading = true;
         if(param.id>0){
             updateEdge(param)
            .then((res) => {
                console.log("GetEdgeInfo", res);      
                if(res.errorCode==='00'){
                    this.$message.success(res.msg);
                    // this.form.id                     = res.data.id 						;
                    // this.form.name                   = res.data.name 					;
                    // this.form.lineType               = res.data.lineType 				;
                    // this.form.connectLinePlace       = res.data.connectLinePlace 		;
                    // this.form.ip                     = res.data.ip 					    ;
                    // this.form.port                   = res.data.port 					;
                    // this.form.url                    = res.data.url 					;
                    // this.form.lineCheckUrl           = res.data.lineCheckUrl 			;
                    // this.form.account				 = res.data.account 				;
                    // this.form.password               = res.data.password 				;
                    // this.form.comments               = res.data.comments 				;
                    // this.form.description            = res.data.description 			;
                    // this.form.notificationTimeLimit  = res.data.notificationTimeLimit 	;
                    // this.form.monitoringMode         = res.data.monitoringMode 		    ;
                    // this.form.lineCheckMode          = res.data.lineCheckMode 			;
                    this.$parent.editFlag = 3;
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;
             }).catch((err) => {
                this.loading = false;
                console.log(err);
            }); 
         }else{
            addEdge(param)
            .then((res) => {
                console.log("GetEdgeInfo", res);      
                if(res.errorCode==='00'){
                    this.$message.success(res.msg);
                    this.form.id                     = res.data.id 						;
                    this.form.name                   = res.data.name 					;
                    this.form.lineType               = res.data.lineType 				;
                    this.form.connectLinePlace       = res.data.connectLinePlace 		;
                    this.form.ip                     = res.data.ip 					    ;
                    this.form.port                   = res.data.port ==0?undefined:	res.data.port;
                    this.form.url                    = res.data.url 					;
                    this.form.lineCheckUrl           = res.data.lineCheckUrl 			;
                    this.form.account				 = res.data.account 				;
                    this.form.password               = res.data.password 				;
                    this.form.comments               = res.data.comments 				;
                    this.form.description            = res.data.description 			;
                    this.form.notificationTimeLimit  = res.data.notificationTimeLimit 	;
                    this.form.monitoringMode         = res.data.monitoringMode 		    ;
                    this.form.lineCheckMode          = res.data.lineCheckMode 			;
                    this.form.status                 = res.data.status;
                   
                    this.$parent.editFlag = 3;
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;   
             }).catch((err) => {
                this.loading = false;
                console.log(err);
            }); 
         }
     },
     getEdgeSmartGateway(){
        console.log("GetEdgeSmartGateway ID3>>>",this.id);
        this.loading=true;
        if(this.id>0){
            getEdgeInfo(this.id)
            .then((res) => {
                this.loading = false;
                let { data } = res;
                console.log("GetEdgeInfo", data);         
                this.form.id                     = res.data.id 						;
                this.form.name                   = res.data.name 					;
                this.form.lineType               = res.data.lineType 				;
                this.form.connectLinePlace       = res.data.connectLinePlace 		;
                this.form.ip                     = res.data.ip 					    ;
                this.form.port                   = res.data.port ==0?undefined:	res.data.port;
                this.form.url                    = res.data.url 					;
                this.form.lineCheckUrl           = res.data.lineCheckUrl 			;
                this.form.account				 = res.data.account 				;
                this.form.password               = res.data.password 				;
                this.form.comments               = res.data.comments 				;
                this.form.description            = res.data.description 			;
                this.form.notificationTimeLimit  = res.data.notificationTimeLimit 	;
                this.form.monitoringMode         = res.data.monitoringMode 		    ;
                this.form.lineCheckMode          = res.data.lineCheckMode 			;
                this.form.status                 = res.data.status;
            }).catch((err) => {
                this.loading = false;
                console.log(err);
            }); 
        }else{
            this.form.id=0;
            this.form.lineType= 1;
            this.form.name= "";
            this.form.ip= "";
            this.form.port= "";
            this.form.url= "";
            this.form.lineCheckUrl= "";
            this.form.account= "";
            this.form.password= "";
            this.form.description= "";
            this.form.comments= "";
            this.form.lineCheckMode= 0;
            this.form.monitoringMode= 0;
            this.form.notificationTimeLimit= 0;
            this.form.connectLinePlace="";
            this.form.status = 0;
            this.loading=false;
        }
     }
  },
  
  watch:{
    "$parent.edgeModalVisible":{
      handler(val){
          console.log("edgeModalVisible>>",val);
          if(val){              
            this.resetForm();
            this.visible = val;
            this.getEdgeSmartGateway();
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
        handler(val){
            console.log("visible",val);
            if(!val){
                this.$parent.edgeModalVisible =  val;
                if(this.$parent.editFlag==3){
                    this.$parent.refresh();
                }
            }
        },
        deep:true,
        immediate:true,
    },
  },

  components: {
  },
};
</script>

<style scoped>
#edgeInfoDialog{
    height: 100%;
    width:100%;
    position: relative;  
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
 div /deep/.ant-modal-body{
    padding: 0px;
}
.container{
    height: 500px;
    overflow: hidden;
}
.main{
    height: 500px;
    padding: 20px 20px 0px 0px;
    overflow: auto;
}
.form-dis-item{margin-right:3px ;}
</style>