import request from '@/utils/request';

// 控制器管理模块----获取职能网关列表 
export function getEdgeInfo(id) {
    return request({
        url: '/bivale/edge/info/'+id,
        method: 'get',
    });
}
// 控制器管理模块----获取职能网关列表 
export function deleteEdgeInfo(id) {
    return request({
        url: '/bivale/edge/delete/'+id,
        method: 'post',
    });
}
// 控制器管理模块----获取职能网关列表
export function getEdgeList(params) {
    return request({
        url: "/bivale/edge/attr/list",
        method: 'post',
        data:params,
    });
}

// 控制器管理模块----获取职能网关列表
export function addEdge(params) {
    return request({
        url: "/bivale/edge/add",
        method: 'post',
        data:params,
    });
}

// 控制器管理模块----获取职能网关列表
export function updateEdge(params) {
    return request({
        url: "/bivale/edge/update",
        method: 'post',
        data:params,
    });
}


// 控制器管理模块----获取职能网关列表
export function uploadFile(params) {
    return request({
        url: "/bivale/edge/file/upload",
        method: 'post',
        processData: false,
        data:params
    });
}


// 控制器管理模块----获取职能网关列表
export function getFileList(params) {
    return request({
        url: "/bivale/edge/file/list",
        method: 'post',
        data:params,
    });
}

// 控制器管理模块----获取职能网关列表
export function upgrade(params) {
    return request({
        url: "/bivale/edge/upgrade",
        method: 'post',
        data:params,
    });
}

export function saveEdgeConfigInfo(params) {
    return request({
        url: "/bivale/edge/config",
        method: 'post',
        data:params,
    });
}