import { render, staticRenderFns } from "./edgeSmartGateway.vue?vue&type=template&id=20d0c11e&scoped=true"
import script from "./edgeSmartGateway.vue?vue&type=script&lang=js"
export * from "./edgeSmartGateway.vue?vue&type=script&lang=js"
import style0 from "./edgeSmartGateway.vue?vue&type=style&index=0&id=20d0c11e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d0c11e",
  null
  
)

export default component.exports